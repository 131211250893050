import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setInfo } from "@/store/auth/profile";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import { setLabo } from "@/store/setting";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { AuthViewModel } from "@/modelviews/auth";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { setFormFieldSubmit, setSelectedLaboToSubmit } from "@/store/register";
import { BaseViewLabo } from "./BaseViewLabo";
import { setLoadData } from "@/store/setting";
import { message } from "@/constants/message";
import { AssetsContext } from "@/context/theme";
import React from "react";

@injectable()
export class BranchViewModel extends BaseViewLabo {
  constructor(
    @inject(TYPES.BranchRepository) private branchRepository: any,
    @inject(TYPES.BranchRepository) private prefix: string,
    @inject(TYPES.BranchRepository) private permission: any
  ) {
    super();
    this.prefix = "branch";
    const res = React.useContext(AssetsContext) as any;
    this.permission = res?.screenPermission;
  }

  private async profile(): Promise<any> {
    await this.checkProfile();
    return this.hooks.getState().setting.labo;
  }
  /**
   * name
   */

  private async dispatchField(): Promise<any> {
    const { items } = this.hooks.getState().setting.branch;
    const { id } = this.hooks.router.query;

    const data = items.find((val: any) => val.id == id);

    if (data)
      Object.keys(data).forEach((key) => {
        if (data[key] as string)
          this.hooks.dispatch(
            setFormFieldSubmit({
              value: data[key].toString(),
              name: key.toString(),
            })
          );
      });
  }

  public async fetchs(param: any, isLoading: boolean = true) {
    if (
      ["branch", "department", "only_me"].includes(
        this.permission?.option as string
      )
    )
      param = { ...param, screen: this.permission?.permission };

    return await (isLoading
      ? this.withLoading(() => this.branchRepository.getBranch(param))
      : this.branchRepository.getBranch(param));
  }

  public async optimizeFetchs(param: any, isLoading: boolean = true) {
    if (
      ["branch", "department", "only_me"].includes(
        this.permission?.option as string
      )
    )
      param = { ...param, screen: this.permission?.permission };

    return await (isLoading
      ? this.withLoading(() => this.branchRepository.getBranchOptimize(param))
      : this.branchRepository.getBranchOptimize(param));
  }

  public async renderData() {
    const res: any = await this.withLoading(() =>
      this.branchRepository.getBranch({ limit: -1 })
    );

    if (res.success) {
      localStorage.setItem("branchs", JSON.stringify(res.data));
      return this.hooks.dispatch(setLoadData({ ...res, type: this.prefix }));
    }
  }

  public async getBrand(action: string): Promise<any> {
    //const { id } = await this.profile();

    await this.renderData();

    if (action == "edit") return await this.dispatchField();

    return true;
  }

  public async updateBrand(param: any): Promise<any> {
    await this.checkProfile();

    const { labo } = this.hooks.getState().setting;
    const { id } = this.hooks.router.query;

    const res: any = await this.withLoading(() =>
      this.branchRepository.updateBranch(param, labo.id, id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items, current_page, last_page } =
      this.hooks.getState().setting.branch;

    await this.renderData();

    this.setMsg(res.message || message.success, 1);
    this.hooks.router.push("/setting/labo/branch");
  }

  public async createBrand(param: any): Promise<any> {
    await this.checkProfile();
    const {
      labo: { id },
    } = this.hooks.getState().setting;
    const res: any = await this.withLoading(() =>
      this.branchRepository.createBranch(param, id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items, current_page, last_page } =
      this.hooks.getState().setting.branch;
    await this.renderData();

    this.setMsg(res.message || message.insert_success, 1);
    this.hooks.router.push("/setting/labo/branch");
  }

  public async deleteBrand(): Promise<any> {
    await this.checkProfile();
    const { labo } = this.hooks.getState().setting;
    const { id } = this.hooks.router.query;

    const res: any = await this.withLoading(() =>
      this.branchRepository.deleteBranch(labo.id, id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items, current_page, last_page } =
      this.hooks.getState().setting.branch;
    await this.renderData();

    this.setMsg(res.message || message.delete_success, 1);
    this.hooks.router.push("/setting/labo/branch");
  }

  public async sort(ids: ArrayBuffer): Promise<any> {
    return this.withLoading(() => this.branchRepository.sort({ ids }));
  }
}
